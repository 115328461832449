@media screen and (min-width: 200px) and (max-width: 599px) {

.formDevis {
  column-gap: 0px !important;
}
.rowFormDevis {
  width: 64%;
}
.choiceDevis {
  display: flex !important;
}
.adressRow {
  /* width: 77% !important; */
  /* display: block !important; */
}
/* #envoiDevis #phone {
  margin-left: -31px;
  width: 100%;
} */
#salarie, #madame {
  /* margin-left: 0 !important; */
}
  #informationMobile {
    margin-top: 62px !important;
  }


  .categoryMobile {
    display: block;
    margin: 0;
    line-height: 0px !important;
  }

  li span {
    font-size: 17px;
  }

  .snip1214 .plan-features {
    width: 19vw !important;
  }

  .priceFinalMobile {
    font-size: 5.5vw !important;
    font-weight: 700;
    transform: translate(0, 0) !important;
  }

  .blocktext {
    font-size: 1.34vw !important;
  }

  #firstYear {
    display: flex;
  }

  .itemsBesoin {
    flex-direction: column !important;
  }

  /* #informationsRow input {
    width: auto !important;
    min-width: 25vw;
    margin-top: 7px !important;
  } */

  .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input,
  .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }

  #postal_code,
  #email {
    width: 114px !important;
  }



  .row-envoi-devis input {
    width: auto !important;
    min-width: 5vw !important;
    max-width: 40vw !important;
  }


  #informationsRow select {
    min-width: 26vw !important;
    /* margin-left: -6px !important; */
  }

  .devis-conjoint,
  .devis-children {
    /* grid-template-columns: repeat(1, 1fr) !important; */
  }

  .button-form {
    width: 60% !important;
    padding: 10px 10px !important;
  }

  .row-envoi-devis {
    /* grid-template-columns: repeat(1, 1fr); */
  }

  .selectForm {
    height: 39px !important;
    margin-top: 7px !important;
    /* width: 12vw !important; */
  }

  .featured {
    background-color: #4f6374;
    color: #ffffff;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
    z-index: 1;
  }

  /* .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
    width: auto !important;
    min-width: 26vw !important;
    height: 72% !important;
    margin-left: -3px;
  } */

  .css-i4bv87-MuiSvgIcon-root {
    justify-content: right !important;
  }

  /* .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
    height: 12vw !important;
    margin-left: 2px !important;
    width: 68vw !important;
    border-color: rgb(173, 173, 173) !important;
  } */

  /* .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    width: 91% !important;
    height: 49px !important;
  } */

  /* .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    position: relative !important;
    top: -8px !important;
  } */
  .buttonGeneral-devis,
  .activeButton {
    /* padding: 4px 1px !important; */
    height: 20px;
  }

  .items-devis {
    /* width: 76% !important; */
  }

  .adressPC {
    display: none;
  }

  .adressMobile {
    display: flex;
  }

  .label-input-envoi-devis {
    width: 100%;
    font-size: 2.5vw;
    /* max-width: 5vw; */
  }

  .phoneMobile {
    justify-content: left;
    display: flex;
    flex-direction: column;

  }

  #num {
    /* width: 2.5vh !important; */
  }

  /* .items {
    width: 82vw !important;
    grid-template-columns: initial;
  } */

  .buttonTarif {
    position: initial !important;
    font-size: 10px !important;
  }

  .buttonTarif img {
    width: 40px !important;
  }

  .buttonPDF {
    top: -8vw !important;
  }
}

@media screen and (min-width: 800px) and (max-width: 1422px) {
  .snip1214 {
    margin: 9% 13% 0% !important
  }

  /* #professionLabel {
    padding-left: 7%;
  } */

  /* .buttonPDF {
    flex-direction: row !important;
    position: relative;
    top: -62px;
    gap: 67px;
  } */
  /* .items {
    width: 58vw !important;
  } */
}

@media screen and (min-width: 777px) and (max-width: 1294px) {
  .buttonPDF {
    /* flex-direction: row !important; */
    /* position: relative; */
    /* top: -62px; */
    top: 0;
    gap: 67px;
  }

}

@media screen and (min-width: 1295px) and (max-width: 1824px) {
  /* .buttonPDF {
    flex-direction: row !important;
    position: absolute;
    gap: 67px;
    top: 0;
  } */
  #planEco:hover {
    background-color: #83acca7a !important;
    transition: .5s;
  }
  
  #planConfort:hover {
    background-color: #829eb84f !important;
    transition: .5s;
  }
  
  #planPrestige:hover {
    background-color: #8091a475 !important;
    transition: .5s;
  }
  
  #planPremium:hover {
    background-color: #7e839160 !important;
    transition: .5s;
  }

}

/* Table for little screen */
@media screen and (min-width: 777px) {

  .snip1214 h2 {
    font-size: 1.5vw;
  }

  .snip1214 .plan-cost {
    padding: 0 !important;
    min-height: 11vw;
  }
  

  .snip1214 .plan {
    width: 17vw;
  }

  .snip1214 .plan-features {
    width: 17vw;
    font-size: 1.4vw !important;
  }

  .blocktext {
    /* font-size: 1.2vw !important; */
    line-height: 21px;
    height: 42px;
    font-size: 8px;
  }
  #tableComparitif {
    margin-left: 82px;
  }
  /* .buttonPDF {
    position: absolute !important;
    top: 0% !important;
    flex-direction: row !important;
    justify-content: center;
    /* width: 69%; */
  /* }  */
  #garantie {
    font-size: 1.5vw !important;
  }
}

@media screen and (min-width: 700px) and (max-width: 1264px) {
  .row-envoi-devis {
    /* grid-template-columns: repeat(1, 1fr) !important; */
  }

  .devis-conjoint,
  .devis-children {
    /* grid-template-columns: repeat(2, 397px) !important; */
  }

  .row-envoi-devis {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Special Table */
@media screen and (min-width: 220px) and (max-width: 599px) {
  #select_street_type {
    margin-left: 5vw;
  }
  #divstreename {
    width: 333px!important;
  }

  #email,  #phone  #postal_code {
    width: 200px !important;
    max-width: 238px !important;
  }
}
@media screen and (min-width: 220px) and (max-width: 777px) {
  .swiper-backface-hidden .swiper-slide {
    width: 100% !important;
  }

  .dobChildren {
    margin-top: 0px;
  }

 

  .plan-features {
    padding: 0 !important;
  }

  .blocktext {
    font-size: 6.9px !important;
  }
  #tableComparitif {
    margin-left: 82px;
  }
  li {
    line-height: 30px !important;
  }

  [id='0_eco'],
  [id='0_confort'],
  [id='0_prestige'],
  [id='0_premium'] {
    height: 50px;
  }

  [id='1_eco'],
  [id='1_confort'],
  [id='1_prestige'],
  [id='1_premium'] {
    height: 50px;
  }

  .plan {
    width: 74% !important;
    margin: 12%;
    border-left: 1px solid #1c467e40;
    border-right: 1px solid #1c467e40;
  }

  .renfort {
    margin-top: 0 !important;
    width: 49%;
  }

  .plan-cost h2 {
    margin-bottom: 5px !important;
    font-size: 1.1em !important;
  }

  /* li {
    font-size: 0.8em !important;
  } */

}

/* Font-size button PDF */
@media screen and (min-width: 715px) {

  /* .buttonTarif {
    position: initial !important;
    font-size: 1.2vw !important;
  } */
  /* .buttonPDF {
    position: absolute !important;
    top: -3vw !important;
  } */
  /* .buttonTarif {
    position: initial !important;
    font-size: 2.5vw !important;
  } */
  .buttonTarif {
    position: initial !important;
    font-size: 1.3vw !important;
    width: 140px;
  }

  .buttonTarif img,
  #garantie img {
    width: 5vw !important;
  }
}

@media screen and (max-width: 714px) {
  #buttonTarifDiv {
    position: initial;
    margin-top: -20px !important;
    /* margin-bottom: 64px !important; */
  }
}


/* SnackBar */
@media screen and (max-width: 599px) {
   #snackTarif, #snackEnvoi, #snackFamille, #snackInformation {
    transform: translate(1vw, 59vh) !important;
    -webkit-transform: translate(1vw, 59vh) !important;
    -moz-transform: translate(1vw, 59vh) !important;
    -ms-transform: translate(1vw, 59vh) !important;
  }
}

@media screen and (min-width: 600px) {
  #snackTarif, #snackEnvoi, #snackFamille, #snackInformation {
    transform: translate(-50%, 59vh) !important;
    -webkit-transform: translate(-50%, 59vh) !important;
    -moz-transform: translate(-50%, 59vh) !important;
    -ms-transform: translate(-50%, 59vh) !important;
  }
}


/* Responsive */ 
@media screen and (max-width: 319px) {
  
  .informationsRow .formControlCSS {
    max-width: 200px;
    min-width: 50% !important;
    width: 162px !important;
    margin-left: 20px !important;
  }
  
}
@media screen and (max-width: 359px) {
  .label-input {
    font-size: 10.5px !important;
    width: 116% !important;
  }
}

@media screen and (min-width: 360px) and (max-width: 499px) {
 .informationsRow .formControlCSS {
    max-width: 200px;
    min-width: 100% !important;
    width: 133px !important;
  }
  .choice .buttonGeneral-devis , .choice .activeButton {
    padding: 4px 1px !important;
    /* width: 50px !important; */
  }
  .label-input {
    font-size: 11.5px !important;
  }
}
@media screen and (min-width: 500px) and (max-width: 1023px) {
 .informationsRow .formControlCSS {
    max-width: 320px;
    min-width: 208px !important;
    width: 170px !important;
  }
}

@media screen and (min-width: 700px) and (max-width: 1023px) {
 .informationsRow .formControlCSS {
    max-width: 320px;
    min-width: 293px !important;
    width: 170px !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1249px) {
 .informationsRow .formControlCSS {
    max-width: 320px;
    min-width: 304px !important;
    width: 170px !important;
  }
}
@media screen and (min-width: 1250px) {
}