.buttonGeneral-devis {
    background: white;
    color: #2866a0;
    box-shadow: 0 0 20px #eee;
  }
  
  .buttonGeneral-devis, .activeButton {
    cursor: pointer;
    background-size: auto;
    padding: 4px 5px;
    text-align: center;
    text-transform: uppercase;
    /* transition: 0.5s; */
    background-size: 200% auto;
    width: 60%;
    display: inline-block;
    font-size: 13px;
    margin: 2px;
    border: none;
    border-radius: 0.25rem;
    border: 1px solid #013f9c;
    font-weight: 600;
    height: 20px;
  }
  
  .activeButton {
    background: #1C467E;
    color: white;
    box-shadow: 0 0 20px #eee;
  }
  

  .buttonGeneral-devis:hover{
    background-image: linear-gradient(
      to right,
      #0061FF 0%,
      #3c87ff 100%
    );
    border: 1px solid transparent;
    color: white;
  }

  .button-form {
    cursor: pointer;
    background: #1C467E;
    padding: 15px 45px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    box-shadow: 0 0 20px #eee;
    display: block;
    border: none;
    margin-left: auto;
    border-radius: 0.25rem;
    margin-right: auto;
    margin-top: 5px;
  }

  .button-form:hover {
    background-position: right center;
    background: #013f9c;
    transition: 0.5s;
    color: #fff;
    text-decoration: none;
  }
  
  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 100px rgb(255, 255, 255) inset
}