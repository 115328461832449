input[type="text"]:disabled {
  color: #8b8b8b;
  border: none !important;
}

.adressRow {
  /* display: grid; */
}

.formDevis .warning {
  display: none;
  visibility: visible;
}

.formDevis {
  display: grid;
  /* grid-template-columns: repeat(2, 1fr); */
  margin-top: 20px;
  column-gap: 20px;
  width: 76%;
  margin: 0 auto;
}

select {
  outline: none;
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
  border-radius: 0;
  cursor: pointer;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  font-family: "Open Sans", sans-serif;
  background-color: white;
}



.dobChildren {
  margin-top: -10px;
}


.css-b62m3t-container {
  /* width: 25vw; */
  text-align: left;
}

.inputLabelTopDiv {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
  width: 100%;
}

.inputLabelTopDiv label {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  padding: 0;
  position: relative;
  display: block;
  transform-origin: top left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 133%;
  position: absolute;
  left: 0;
  top: 0;
  -webkit-transform: translate(0, -1.5px) scale(0.75);
  -moz-transform: translate(0, -1.5px) scale(0.75);
  -ms-transform: translate(0, -1.5px) scale(0.75);
  transform: translate(0, -1.5px) scale(0.75);
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  margin-top: -15px;
}

.items-devis {
  /* display: flex; */
  /* width: 100%; */
  /* overflow-x: clip; */
  /* gap: 24px; */
}

::placeholder,
.css-14el2xx-placeholder {
  font-size: max(1.7vw, 13px);
}

/* .inputLabelTopDiv input, .inputLabelTopDiv div {
  margin-top: 24px;
} */
.row-envoi-devis {
  display: grid !important;
  grid-gap: 25px;
  grid-template-columns: repeat(2, 1fr);
}

.children .items-devis,
#conjoint .items-devis {
  text-align: left;
}

#envoiDevis .input-form {
  display: flex;
  /* width: 95%; */
  text-transform: capitalize;
  padding-left: 0;
}

#envoiDevis #phone {
  width: 90%;
}

#envoiDevis select {
  display: flex;
}

.label-input-envoi-devis {
  display: block;
  color: #335e97;
  /* width: 140px; */
  min-width: 10px;
  margin-right: 50px;
  font-size: 0.74rem;
  text-align: left;
}




.adressPC {
  display: flex;
}

.adressMobile {
  display: none;
}

/**
  * Input
  */
.select-search__input {
  outline: none;
  box-sizing: border-box;
  padding: 10px;
  border-radius: 0;
  cursor: pointer;
  width: 100% !important;
  height: 41px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  font-family: "Open Sans", sans-serif;
  background-color: white;
}

.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.select-search__input:not([readonly]):focus {
  cursor: initial;
}

/**
  * Options wrapper
  */
.select-search__select {
  background: #fff;
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
}

/**
  * Options
  */
.select-search__options {
  list-style: none;
  padding: 0;
}

/**
  * Option row
  */
.select-search__row:not(:first-child) {
  border-top: 1px solid #eee;
}

/**
  * Option
  */
.select-search__option,
.select-search__not-found {
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 16px;
  background: #fff;
  border: none;
  outline: none;
  font-family: "Noto Sans", sans-serif;
  font-size: 14px;
  text-align: left;
  cursor: pointer;
}

.select-search--multiple .select-search__option {
  height: 48px;
}

.select-search__option.is-selected {
  background: #3484c2;
  color: #fff;
}

.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
  background: #3e97db5b;
}

.select-search__option.is-highlighted.is-selected,
.select-search__option.is-selected:hover {
  background: #3e97dba1;
  color: #fff;
}

/**
  * Group
  */
.select-search__group-header {
  font-size: 10px;
  text-transform: uppercase;
  background: #eee;
  padding: 8px 16px;
}

/**
  * States
  */
.select-search.is-disabled {
  opacity: 0.5;
}

.select-search.is-loading .select-search__value::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
  background-size: 11px;
}

.select-search:not(.is-disabled) .select-search__input {
  cursor: pointer;
}

/**
  * Modifiers
  */
.select-search--multiple {
  border-radius: 3px;
  overflow: hidden;
}

.select-search:not(.is-loading):not(.select-search--multiple) .select-search__value::after {
  transform: rotate(45deg);
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  pointer-events: none;
}

.select-search--multiple .select-search__input {
  cursor: initial;
}

.select-search--multiple .select-search__input {
  border-radius: 3px 3px 0 0;
}

.select-search--multiple:not(.select-search--search) .select-search__input {
  cursor: default;
}

.select-search:not(.select-search--multiple) .select-search__input:hover {
  border-color: #2fcc8b;
}

.select-search:not(.select-search--multiple) .select-search__select {
  position: absolute;
  z-index: 2;
  top: 44px;
  right: 0;
  left: 0;
  border-radius: 3px;
  overflow: auto;
  max-height: 360px;
}

.select-search--multiple .select-search__select {
  position: relative;
  overflow: auto;
  max-height: 260px;
  border-top: 1px solid #eee;
  border-radius: 0 0 3px 3px;
}

.select-search__not-found {
  height: auto;
  padding: 16px;
  text-align: center;
  color: #888;
}


/* Envoi Devis Family */
#envoiDevisForm {
  margin: 0 !important;
}