@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');

h1, h2, h3, h4, h5, h6, button, label, p , li, span{
  font-family: 'Noto Sans', sans-serif !important;
}
body {
  overflow-x: hidden !important;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  overflow: visible !important;
  font-size: 0.85rem !important; 
}

.devis-complementaire {
  top: 11px;
  /* position: absolute; */
  /* width: 44vw; */
}

.buttonGeneral {
  cursor: pointer;
  background: white;
  /* background-image: linear-gradient( to right, #1c467e 0%, #2866a0 51%, #3386c1 100% ); */
  color: #2866a0;
  padding: 15px 45px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  box-shadow: 0 0 20px #eee;
  border: none;
  margin: 10px;
  border: 1px solid #3484c2;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

#envoiDevis .activeButton, #envoiDevis .buttonGeneral-devis { 
  /* height: 20px; */
}


a {
  cursor: pointer;
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
